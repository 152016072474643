//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { LynxService } from "../../services/lynx.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { EventService } from "../../services/event.service";
import { AlertService } from "../../services/alert.service";
import { TelemetryService } from "../../services/telemetry.service";

@TypeManagerDecorator("s25-lynx")
@Component({
    selector: "s25-lynx",
    template: `
        @if (this.init) {
            <div class="c-margin-left--single">
                @if (this.hasInstances) {
                    <div>
                        <label class="c-margin-top--single ngBold"> Pause Section </label>
                        <s25-toggle-button
                            [(modelValue)]="this.paused"
                            (modelValueChange)="setState($event)"
                        ></s25-toggle-button>
                        <p class="ngFinePrint">
                            When a section is part of an active import or export extract set, pausing prevents it from
                            updating in 25Live when it is changed in your Student Information System (SIS) and prevents
                            it from updating in your SIS when it is changed in 25Live.
                        </p>
                        <p class="ngFinePrint ngBold">
                            If you un-pause this section, you must re-import it via LYNX to ensure that 25Live reflects
                            what is in your SIS.
                        </p>
                    </div>
                }
                @if (!this.hasInstances) {
                    <div>
                        <p>There are no LYNX instances associated with the current user.</p>
                    </div>
                }
            </div>
        }
    `,
    styles: ["p.ngFinePrint {margin-top: 1.5em; max-width:500px;} div.toggle-wrapper {height: auto;}"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25LynxComponent implements OnInit {
    @Input() itemId: number;

    alienUid: string;
    init: boolean = false;
    paused: boolean;
    hasInstances: boolean;
    multiInstances: boolean;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    setState(paused: boolean) {
        if (!paused) {
            return AlertService.confirm(
                "Reminder: " +
                    "If you un-pause this section, you must re-import it via LYNX to ensure that 25Live reflects what is in your SIS.",
            ).then((confirmUnpause) => {
                if (confirmUnpause) {
                    TelemetryService.send("LYNXTab", "Unpause");
                    return LynxService.setState(this.alienUid, paused).then(
                        (resp) => {},
                        (error) => {
                            S25Util.showError(error);
                        },
                    );
                }
            });
        } else {
            TelemetryService.send("LYNXTab", "Pause");
            return LynxService.setState(this.alienUid, paused).then(
                (resp) => {},
                (error) => {
                    S25Util.showError(error);
                },
            );
        }
    }

    ngOnInit() {
        return EventService.getAlienUid(this.itemId).then((alienUid) => {
            this.alienUid = alienUid;
            return S25Util.all({
                instances: LynxService.getUserInstances(),
                paused: LynxService.getSingleState(this.alienUid),
            }).then(
                (resp) => {
                    this.hasInstances = resp.instances.length > 0;
                    this.multiInstances = resp.instances.length > 1;
                    this.paused = !!resp.paused;
                    this.init = true;
                    this.cd.detectChanges();
                },
                (error) => {
                    S25Util.showError(error);
                },
            );
        });
    }
}
