import { NgModule } from "@angular/core";
import { S25MeetingPatternGridPageComponent } from "./s25.meeting.pattern.grid.page.component";
import { S25MeetingPatternGridListComponent } from "./s25.meeting.pattern.grid.list.component";
import { S25MeetingPatternGridComponent } from "./s25.meeting.pattern.grid.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { CommonModule } from "@angular/common";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { PopoverModule } from "../s25-popover/popover.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25VirtualGridModule } from "../s25-virtual-grid/s25.virtual.grid.module";
import { S25MeetingPatternGridEventComponent } from "./s25.meeting.pattern.grid.event.component";
import { S25TimeBubbleComponent } from "../../standalone/s25.time.bubble.component";
import { S25BubbleComponent } from "../../standalone/s25.bubble.component";
import { S25ScrollMinimapComponent } from "../../standalone/s25-scroll-minimap/s25.scroll.minimap.component";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { FormsModule } from "@angular/forms";
import { S25ButtonComponent } from "../../standalone/s25.button.component";

@NgModule({
    imports: [
        S25TableModule,
        CommonModule,
        S25LoadingInlineModule,
        S25ItemModule,
        PopoverModule,
        S25IconModule,
        S25ScrollMinimapComponent,
        S25CheckboxModule,
        S25ToggleButtonModule,
        S25VirtualGridModule,
        S25TimeBubbleComponent,
        S25BubbleComponent,
        S25ModalModule,
        S25DropdownPaginatedModule,
        S25MultiselectModule,
        FormsModule,
        S25ButtonComponent,
    ],
    exports: [S25MeetingPatternGridPageComponent],
    declarations: [
        S25MeetingPatternGridPageComponent,
        S25MeetingPatternGridListComponent,
        S25MeetingPatternGridComponent,
        S25MeetingPatternGridEventComponent,
    ],
    providers: [S25MeetingPatternGridPageComponent],
})
export class S25MeetingPatternGridModule {}
