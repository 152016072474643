//@author: devin

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25LynxComponent } from "./s25.lynx.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";

@NgModule({
    declarations: [S25LynxComponent],
    imports: [CommonModule, S25ToggleButtonModule],
    exports: [S25LynxComponent],
    providers: [S25LynxComponent],
})
export class S25LynxModule {
    constructor() {}
}
