import { S25Util } from "../../util/s25-util";
import { SwarmSchedule } from "../s25-swarm-schedule/SwarmSchedule";
import { ProfileUtil } from "../s25-datepattern/profile.util";
import { BOARD_CONST } from "../s25-swarm-schedule/s25.board.const";
import { EventSummary } from "../s25-swarm-schedule/s25.event.summary.service";
import DowChar = EventSummary.DowChar;
import { MPG } from "./s25.meeting.pattern.grid.component";
import { MeetingPatternGrid } from "../s25-swarm-schedule/meeting.pattern.grid.service";
import { BoardOccUtil } from "../s25-swarm-schedule/s25.board.occ.util";

export namespace MPGUtil {
    export function getLocationFromTop(data: MPG.Data, item: MPG.Item) {
        // Figure out the new row, based on top
        return atPercentClosest(data.rows, item.top);
    }

    export function atPercentClosest<T>(arr: T[], percent: number): T {
        const index = S25Util.clamp(Math.round((percent / 100) * arr.length), 0, arr.length - 1);
        return arr[index];
    }

    export function mapGridItemToItemTo(data: MPG.Data, item: MPG.Item): SwarmSchedule.EventI {
        const { dow, startHour, endHour } = item.candidate;
        const { profileCode, uuid, sourceRoomId } = item.data;
        const locationId = getLocationFromTop(data, item).id;
        const newProfileCode = getNewProfileCode(profileCode, dow);

        return {
            itemUUID: uuid,
            sourceRoomId,
            start: S25Util.date.toS25ISOTimeStrFromHours(startHour), // Dependent on x-position
            end: S25Util.date.toS25ISOTimeStrFromHours(endHour), // Dependent on x-position & width
            profileCode: newProfileCode, // Dependent on x-position
            dow, // Dependent on x-position
            roomId: Number(locationId), // Dependent on y-position
            rowCol: {
                rowUUID: String(locationId), // Dependent on y-position
                colUUID: `${dow}-${Math.floor(startHour)}`, // Dependent on x-position
            },
            timestamp: Date.now(),
        };
    }

    export function getNewProfileCode(currentCode: string, dow: string) {
        const model = ProfileUtil.getProfileModel(
            null,
            currentCode,
            ProfileUtil.getProfileCodeThroughDate(currentCode),
            null,
        );

        // Update model with new dows
        const dows = new Set(dow.split("").map((d: DowChar) => BOARD_CONST.dowMapInverse[d]));
        for (let dow of model.daysOfWeek) dow.chosen = dows.has(dow.abbr);

        return ProfileUtil.getProfileCodeByModel(model);
    }

    export function getItemTos(data: MPG.Data, items: MPG.Item[]) {
        return items.map(mapGridItemToItemTo.bind(this, data)) as SwarmSchedule.EventI[];
    }

    export function mapLocationToRow(location: SwarmSchedule.BoardRoom): MPG.Row {
        const { roomId, roomName, maxCapacity, partition } = location;
        const features = location.features || [];
        return {
            id: roomId,
            data: {
                name: roomName + (roomId === -1 ? "" : ` (${maxCapacity})`),
                features: features.map((feature) => ({
                    id: feature.featureId,
                    name: feature.featureName,
                })),
                partition: partition && {
                    id: partition.partitionId,
                    name: partition.partitionName,
                },
                maxCapacity: maxCapacity || 0,
            },
        };
    }

    export function getItemData(event: MeetingPatternGrid.Item): MPG.ItemData {
        return {
            name: event.itemName,
            uuid: event.uuid,
            event,
            profileCode: event.profileCode,
            roomId: event.roomId,
            sourceRoomId: event.roomId,
            dow: event.dow,
            startHour: S25Util.date.timeToHours(event.startTime),
            endHour: S25Util.date.timeToHours(event.endTime),
            noShadows: event.roomId === -1, // No shadows for unassigned items (ANG-3556)
            lastChange: 0,
            organization: event.objRef.organization && {
                id: event.objRef.organization.organizationId,
                name: event.objRef.organization.organizationName,
                partitions: event.objRef.organization.partitions?.map((partition) => ({
                    priority: partition.priority,
                    list: partition.list?.map((p) => ({ id: p.partitionId, name: p.partitionName })) || [],
                })),
            },
            features:
                event.objRef.features
                    ?.filter((f) => f)
                    .map((feature) => ({
                        id: feature.featureId,
                        name: feature.featureName,
                    })) || [],
            headCount: event.headCount || 0,
            canHaveSOC: event.canHaveSOC,
            occs: event.occs,
        };
    }

    /**
     * Scores rows by relevance for assigning the current item to a new location
     */
    export function scoreRows(rows: MPG.Row[], item: MPG.Item, minFillRatio: number): Map<MPG.Row["id"], number> {
        const features = new Set(item.data.features.map((feature) => feature.id));

        // Map Partition -> Priority
        const partitionPriority: Record<number, number> = {};
        for (let { priority, list } of item.data.organization?.partitions || []) {
            for (let part of list) partitionPriority[part.id] = priority;
        }

        return new Map(
            rows.map((row) => {
                const score = scoreRow(Number(row.id), row.data, item.data, minFillRatio, features, partitionPriority);
                return [row.id, score];
            }),
        );
    }

    export function scoreRow(
        roomId: number,
        rowData: Pick<MPG.RowData, "features" | "partition" | "maxCapacity">,
        roomData: Pick<MPG.ItemData, "headCount" | "roomId">,
        minFillRatio: number,
        features: Set<number>,
        partitionPriority: Record<number, number>,
    ): number {
        // Score features based on how many of them the location has
        const matchingFeatures = S25Util.array.count(rowData.features, (feature) => +features.has(feature.id));

        // Score partition based on priority, where higher priority is worse
        const priority = partitionPriority[rowData.partition?.id];
        const partitionScore = priority ? 1 / priority : 0;

        // Score utilization
        const unusedHeadcount = rowData.maxCapacity - roomData.headCount;
        let utilizationScore = 1 / (Math.abs(unusedHeadcount) || 0.9); // 0 => 0.9 to avoid division by 0 and to keep scores manageable
        if (unusedHeadcount < 0) utilizationScore -= 0.0001; // Minor penalty for being over capacity
        if (roomData.headCount / (rowData.maxCapacity || 0.001) < minFillRatio) utilizationScore -= 1; // Major penalty for being less than the min fill ratio

        // If row is unassigned row or the item's current row it should be at the top
        const unassignedRowScore = roomId === -1 ? 1000 : 0;
        const itemRowScore = roomData.roomId === roomId ? 100 : 0;
        const rowScore = unassignedRowScore + itemRowScore;

        // Add scores up
        return matchingFeatures + partitionScore + utilizationScore + rowScore;
    }

    export function updateItemTimestamps(items: MPG.Item[]) {
        for (let item of items) item.data.lastChange = Date.now();
    }

    export function updateCandidateData(data: MPG.Data, items: MPG.Item[]) {
        for (const item of items) {
            const room = MPGUtil.getLocationFromTop(data, item);
            const start = S25Util.date.parse(S25Util.date.toS25ISOTimeStrFromHours(item.candidate.startHour));
            const end = S25Util.date.parse(S25Util.date.toS25ISOTimeStrFromHours(item.candidate.endHour));

            const occs = BoardOccUtil.getOccurrences({
                dow: item.candidate.dow as SwarmSchedule.DayPatterns,
                start,
                end,
                profileCode: item.data.profileCode,
                occs: item.data.occs.map((occ) => ({
                    reservation_start_dt: new Date(occ.reservation_start_dt),
                    reservation_end_dt: new Date(occ.reservation_end_dt),
                })),
            }).map((occ) => ({
                reservation_start_dt: S25Util.date.toS25ISODateTimeStrFromDate(occ.reservation_start_dt),
                reservation_end_dt: S25Util.date.toS25ISODateTimeStrFromDate(occ.reservation_end_dt),
            }));

            Object.assign(item.candidate, { room, roomId: Number(room.id), occs });
        }
    }
}
