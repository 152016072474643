import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { Table } from "../s25-table/Table";
import { Bind } from "../../decorators/bind.decorator";
import { BoardService } from "../s25-swarm-schedule/s25.board.service";
import { GenericTableButtonComponent } from "../s25-table/generics/generic.table.button.component";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { GenericTableMultiselectSearchCriteriaComponent } from "../s25-table/generics/generic.table.multiselect.search.criteria.component";
import { S25Util } from "../../util/s25-util";
import { S25ItemI } from "../../pojo/S25ItemI";
import { CacheRepository } from "../../decorators/cache.decorator";
import { SwarmSchedule } from "../s25-swarm-schedule/SwarmSchedule";
import { GenericTableListComponent } from "../s25-table/generics/generic.table.list.component";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { UserprefService } from "../../services/userpref.service";
import { ContactService } from "../../services/contact.service";

@Component({
    selector: "s25-ng-meeting-pattern-grid-list",
    template: `
        @if (isInit) {
            <s25-ng-table
                [dataSource]="tableModel"
                [columnSortable]="true"
                [hasFilter]="true"
                [hasRefresh]="true"
                [unlimitedWidth]="true"
                [noResultsMessage]="'You have not created any Optimizer runs and none have been shared with you'"
            ></s25-ng-table>
            @if (boards?.length) {
                <p class="viewHint">Select "View" in the table above to view a board</p>
            }
        }
    `,
    styles: `
        .viewHint {
            text-align: center;
            padding: 1rem;
        }
    `,
})
export class S25MeetingPatternGridListComponent implements OnInit {
    @Output() viewBoard = new EventEmitter<SwarmSchedule.BoardI>();

    @ViewChild(S25TableComponent) table: S25TableComponent;

    isInit = false;
    boards: SwarmSchedule.BoardI[];
    tableModel: Table.DataSource;
    dateFormat: Awaited<ReturnType<typeof UserprefService.getS25Dateformat>>;

    ngOnInit() {
        this.tableModel = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                { id: "board", header: "Name" },
                { id: "locationSearch", header: "Location Search" },
                { id: "eventSearch", header: "Event Search" },
                { id: "lastRun", header: "Last Prepared" },
                {
                    id: "editors",
                    header: "Editors",
                    content: { component: GenericTableMultiselectSearchCriteriaComponent },
                    sortable: false,
                },
                GenericTableButtonComponent.Column("View", (row) => this.viewBoard.emit(row.data.board)),
            ],
        };
        this.isInit = true;
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("BoardService");
        const [boards, dateFormat, currentUsername] = await Promise.all([
            BoardService.getBoards(),
            UserprefService.getS25Dateformat(),
            ContactService.getCurrentUsername(),
        ]);
        this.boards = boards;
        this.dateFormat = dateFormat;

        const rows: Table.Row[] = boards.map((board) => {
            const multiselectModel: MultiselectModelI = {
                showResult: true,
                buttonText: "Share",
                extractItems: (items: any) => {
                    return items.searchCriteria.item.map((item: any) => ({
                        itemId: item.itemName,
                        itemName: item.itemName,
                    }));
                },
                addAction: (item: S25ItemI) => {
                    BoardService.shareBoard(board.boardId, board.boardUUID, item.itemId as string).catch(
                        S25Util.showError,
                    );
                },
                removeAction: (item: S25ItemI) => {
                    BoardService.unshareBoard(board.boardId, board.boardUUID, item.itemId as string).catch(
                        S25Util.showError,
                    );
                },
            };

            let editors: Table.Cell = {
                inputs: {
                    type: "contacts",
                    customFilterValue: "&is_r25user=1&itemName=username",
                    selectedItems: board.sharedUsers.map((user) => ({
                        itemId: user.username,
                        itemName: user.username,
                    })),
                    modelBean: multiselectModel,
                    popoverOnBody: true,
                },
            };
            if (board.shared) {
                const users = [
                    ...board.sharedUsers
                        .filter((item) => item.username !== currentUsername)
                        .map((item) => ({ text: item.username })),
                ];
                editors = {
                    component: GenericTableListComponent,
                    inputs: {
                        items: users,
                    },
                };
            }

            return {
                id: board.boardId,
                name: board.boardName,
                data: { board },
                cells: {
                    board: { text: board.boardName },
                    locationSearch: { text: board.locationQueryName },
                    eventSearch: { text: board.eventQueryName },
                    lastRun: {
                        text: S25Datefilter.transform(board.lastPrepared, this.dateFormat),
                        sortValue: board.lastPrepared,
                    },
                    editors,
                },
            };
        });
        return { rows };
    }

    forceRefresh() {
        return this.table?.refresh(true);
    }
}
